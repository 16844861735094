<template>
  <b-card no-body :class="{ 'is-default': isDefault }">
    <b-card-header>
      <div class="card-title">
        <div>
          <h3 class="title" v-html="addressTitle"></h3>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <template v-if="addressExist">
        {{address.firstname}} {{address.lastname}}<br>
        {{addressJoinSteet}}<br>
        {{address.city}}{{address.region ? ', ' + address.region.region : ''}}, {{address.postcode}}, {{address.country_code}}<br>
        <span v-if="address.telephone">T: {{address.telephone}}</span>
      </template>
      <template v-else>
        <span v-t="emptyMessage"></span>
      </template>
    </b-card-body>
    <b-card-footer class="d-flex justify-content-between">
      <template v-if="!isDefault">
        <button type="button" class="btn btn-none bcm-link bcm-link-classic" v-t="'Delete'" @click="$emit('deleteCard', address.id)"></button>
      </template>
      <router-link :to="{ name: 'editAddress', params: { id: address.id} }" class="bcm-link bcm-link-classic ml-auto" v-t="'Edit'"></router-link>
    </b-card-footer>
  </b-card>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    addressTitle: {
      type: String,
      default: ''
    },
    emptyMessage: {
      type: String,
      default: () => 'addressForm.noAddrBody'
    }
  },
  computed: {
    addressExist () {
      return Object.keys(this.address).length
    },
    addressJoinSteet () {
      return (this.address && this.address.street) ? this.address.street.join(', ') : ''
    }
  }
}
</script>
