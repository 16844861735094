<template>
  <div>
    <modalTerms v-model="modalTerms.open" :pageID="modalTerms.pageID" v-on:close="openModalTerms ({open: false, pageID: ''})"></modalTerms>
    <b-form class="text-left" @submit.prevent="handleSubmit">
      <!-- DETAIL FORM -->
      <b-row no-gutters>
        <b-col md="12">
          <b-form-group id="groupGender" label-for="gender">
            <b-form-radio-group
              id="gender"
              v-model="form.gender"
              :options="genderOptions"
              text-field="label"
              name="gender-options">
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="grid-two-column" no-gutters>
        <b-col cols="12">
          <b-form-group id="groupFirstname" >
            <base-input
              name="firstname"
              v-validate="'required'"
              v-model="form.firstname"
              :placeholder="$t('address.FirstName') + '*'"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('firstname'),
                  text: errors.first('firstname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="groupLastname" >
            <base-input
              name="lastname"
              v-validate="'required'"
              v-model="form.lastname"
              :placeholder="$t('address.LastName') + '*'"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('lastname'),
                  text: errors.first('lastname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="grid-two-column" no-gutters>
        <b-col cols="12">
          <b-form-group id="groupDoB" class="date-of-birth" label-for="dob">
            <no-ssr>
              <input-dob v-model="form.date_of_birth" id="dob" selectClass="select-classic"></input-dob>
            </no-ssr>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="groupEmail">
            <base-input
              name="email"
              v-validate="'required|email'"
              v-model="form.email"
              :placeholder="$t('address.Email') + '*'"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('email'),
                  text: errors.first('email')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="grid-two-column" no-gutters>
        <b-col cols="12">
          <b-form-group id="groupCurrentpassword" >
            <base-input
              name="currentpassword"
              autocomplete="off"
              v-validate="changePassword ? 'required' : ''"
              v-model="currentPassword"
              :showHidePass="true"
              :placeholder="$t('form.currentPassword') + '*'"
              type="password"
              :validations="[
                {
                  condition: errors.has('currentpassword'),
                  text: errors.first('currentpassword')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="groupChangePassword" class="my-0">
            <base-check-box
              name="changePassword"
              v-model="changePassword"
              :label="$t('changePassword')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- change password section -->
      <b-collapse id="collapse-change-password" v-model="changePassword" >
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group id="groupNewpassword" >
              <base-input
                name="newpassword"
                ref="newpassword"
                autocomplete="new-password"
                v-validate="changePassword ? 'required' : ''"
                :showHidePass="true"
                v-model="newPassword"
                :placeholder="$t('form.newPassword') + '*'"
                type="password"
                :validations="[
                  {
                    condition: errors.has('newpassword'),
                    text: errors.first('newpassword')
                  }
                ]"
              >
              </base-input>
            </b-form-group>
          </b-col>
          <!-- check new password -->
          <b-col md="6">
            <b-form-group id="groupCheckNewpassword" >
              <base-input
                name="checknewpassword"
                autocomplete="new-password"
                v-validate="changePassword ? 'required|confirmed:newpassword' : ''"
                :showHidePass="true"
                v-model="checkNewPassword"
                :placeholder="$t('form.checknewPassword') + '*'"
                type="password"
                :validations="[
                  {
                    condition: errors.has('checknewpassword'),
                    text: errors.first('checknewpassword')
                  }
                ]"
              >
              </base-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
      <!-- PRIVACY -->
      <!-- <div class="col-sm-6 p-0">
        <b-form-group id="privacyConsent" class="my-0">
          <base-check-box
            :disabled="true"
            :value="true"
            name="privacyCheckbox"
            :label="this.$t('termsPrivacy')"
          />
        </b-form-group>
      </div> -->
      <b-button type="submit" variant="primary" style="display:none">{{ $t('Send') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import BaseInput from 'theme/components/Form/BaseInput'
import BaseCheckBox from 'theme/components/Form/BaseCheckBox'
import BaseSelect from 'theme/components/Form/BaseSelect'
import InputDob from 'theme/components/Form/Dob'
import Logger from '@/services/Logger'
import ModalTerms from 'theme/components/Modal/Terms'
import Config from 'config'
import { EventBus, scrollTo } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
import { toast } from '@/modules/notifications'
import _ from 'lodash'

const modelForm = {
  gender: 1,
  prefix: '',
  firstname: '',
  lastname: '',
  email: '',
  date_of_birth: '',
  password: '',
  is_subscribed: false,
  profiling: false
}
export default {
  inject: ['$validator'],
  data () {
    return {
      form: { ...modelForm },
      currentPassword: '',
      checkNewPassword: '',
      newPassword: '',
      isModify: true,
      readOnly: false,
      changePassword: false,
      is_subscribed2: false,
      modalTerms: {
        open: false,
        pageID: ''
      }
    }
  },
  props: {
    customer: {
      type: Object,
      default: () => ({ ...modelForm })
    },
    onlyNewsletter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVatEnable () {
      return this.$store.getters['ui/isVatEnable']
    },
    isSubscribed () {
      return this.customer && this.customer.is_subscribed
    },
    labelLinkPrivacy () {
      return this.$t('termsMarketing', { linkPrivacy: this.privacyUrl })
    },
    genderOptions () {
      const _this = this
      // apply the reverse order to insert the gender "Male" as the first element
      return Config.Theme.forms.genderValues.map(item => ({
        value: item.value,
        label: _this.$t('registerPage.gender.' + item.label)
      })).sort().reverse()
    },
    privacyUrl () {
      return this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
    }
  },
  methods: {
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    initForm () {
      this.$nextTick(function () {
        _.assign(this.form, this.customer)
        this.$validator.reset()
      })
    },
    editPersonalInfo () {
      this.isModify = true
      this.readOnly = false
    },
    handleSubmit () {
      const self = this
      self.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.form.password = this.currentPassword
          self.$store.commit('ui/showLoader')
          const promArr = []
          promArr.push(self.$store.dispatch('user/update', self.form))
          if (self.changePassword) {
            promArr.push(self.$store.dispatch('user/changePass', {
              currentPassword: self.currentPassword,
              newPassword: self.newPassword
            }))
          }
          if (self.form.is_subscribed) {
            promArr.push(self.$store.dispatch('user/subscribeCustomer', { email: self.form.email, nlType: '' }))
          }
          if (!self.form.is_subscribed) {
            promArr.push(self.$store.dispatch('user/unsubscribeCustomer', { email: self.form.email }))
          }
          Promise.all(promArr)
            .then(user => {
              // this.readOnly = true
              // this.isModify = false
              self.$store.commit('ui/hideLoader', false)
              toast.success(self.$t('Change successful!'))
              self.$validator.reset()
            })
            .catch(error => {
              Logger.error(error)
              this.$store.commit('ui/hideLoader', false)
              error && error.message && toast.error(self.$t(error.message))
            })
        } else {
          scrollTo({
            className: 'is-invalid'
          })
        }
      })
    }
  },
  mounted () {
    if (this.customer) {
      _.assign(this.form, this.customer)
    }
    EventBus.$on('user:update', this.initForm.bind(this))
  },
  destroyed () {
    EventBus.$off('user:update', this.initForm.bind(this))
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseCheckBox,
    InputDob,
    ModalTerms
  }
}
</script>
