<template>
  <div class="container-fluid">
    <b-row class="box-info">
      <b-col sm="6" lg="3" class="bcm-card-wrapper" v-if="preferedBilling">
        <address-tile :address="preferedBilling" :addressTitle="$t(preferedBilling.default_billing ? 'dashboard.my-default-billing' : 'BillingAddress')" :isDefault="preferedBilling.default_billing" @deleteCard="showModalConfirm" />
      </b-col>
      <b-col sm="6" lg="3" class="bcm-card-wrapper" v-if="preferedShipping">
        <address-tile :address="preferedShipping" :addressTitle="$t(preferedShipping.default_shipping ? 'dashboard.my-default-shipping' : 'ShippingAddress')" :isDefault="preferedShipping.default_shipping" @deleteCard="showModalConfirm" />
      </b-col>
      <b-col sm="6" lg="3" class="bcm-card-wrapper" v-for="addr in otherAddress" :key="addr.id">
        <address-tile :address="addr" :addressTitle="$t('addressForm.addAddrTitle')" @deleteCard="showModalConfirm" />
      </b-col>

      <b-col sm="6" lg="3" class="bcm-card-wrapper add-new">
        <b-card no-body>
          <b-card-body>
            <router-link :to="{ name: 'editAddress', params: { id: 'new'} }">
              <i class="bcm-icon bcm-icon-18-plus"></i>
              <span v-t="'addressForm.add-address'"></span>
            </router-link>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
    <modal-confirm
    :modal-show="modalShowConfirm"
    :message="$t('messageRemoveAddr')"
    :options="editObj"
    @updateModalConfirm="updateModalConfirm"
    @cancel="updateModalConfirm"
    @confirm="removeAddress"
    />
  </div>
</template>

<script>
import { DefaultAddress } from '@/store/modules/checkout/defaultValues'
import ModalConfirm from 'theme/components/Modal/ModalConfirm.vue'
import AddressTile from 'theme/components/MyAccount/AddressTile'
import { toast } from '@/modules/notifications'

export default {
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      editObj: {
        index: null,
        address: { ...DefaultAddress }
      },
      preferedBilling: false,
      preferedShipping: false,
      modalShowConfirm: false
    }
  },
  computed: {
    hasAddresses () {
      return this.addresses.length > 0
    },
    otherAddress () {
      const _this = this
      return this.addresses.filter((address) => {
        if (!(address.default_billing || address.default_shipping)) {
          return true
          // _this.$set(_this.otherAddress, _this.otherAddress.length, address)
        } else {
          if (address.default_billing) {
            _this.preferedBilling = address
          }
          if (address.default_shipping) {
            _this.preferedShipping = address
          }
        }
        return false
      })
    }
  },
  methods: {
    showModalConfirm (index) {
      this.editObj.index = index
      this.modalShowConfirm = true
    },
    updateModalConfirm (value) {
      this.modalShowConfirm = value
    },
    removeAddress (obj) {
      this.modalShowConfirm = false
      this.$store.commit('ui/showLoader')
      this.$store.dispatch('user/deleteAddress', obj.index)
        .then(user => {
          this.$store.commit('ui/hideLoader', false)
          toast.success(this.$t('Change successful!'))
        })
        .catch(res => {
          this.$store.commit('ui/hideLoader', false)
          toast.error(this.$t(res.body.result))
        })
    }
  },
  components: {
    ModalConfirm,
    AddressTile
  }
}
</script>
